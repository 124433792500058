<template>
  <div>
    <v-tabs
      align-with-title
    >
      <v-tabs-slider color="accent"></v-tabs-slider>

      <v-tab
        :to="{ name: 'Export Files (original)' }"
      >
      Export Files (original)
      </v-tab>
      <v-tab
        :to="{ name: 'Export Files (beta)' }"
      >
      Export Files (beta)
      </v-tab>
    </v-tabs>
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'ExportFiles',
  components: {
  },
};
</script>
